import BroadcastChannel from "@/utils/broadcasts";
import { Readable, Writable, derived, get, readable, writable } from "svelte/store";
import { Timeout } from "@/utils/promises";


type Unsubscriber = () => void;
type Subscription<T> = (value: T) => void;
type Updater<T> = (value: T) => T;


export function property<T, N extends keyof T>(parent: Writable<T>|undefined, name: N): Writable<T[N]>;
export function property<T, N extends keyof T>(parent: Readable<T>|undefined, name: N): Readable<T[N]>;
export function property<T, N extends keyof T>(_parent: Readable<T>|Writable<T>|undefined, name: N): Readable<T[N]>|Writable<T[N]> {
    const parent = _parent as any as Writable<T>|undefined;
    return {
        subscribe(func: Subscription<T[N]>): Unsubscriber {
            if (parent === undefined)
                return () => {};

            return parent.subscribe((obj: T) => {
                if (!obj) return func(undefined as any);
                func(obj[name]);
            });
        },

        set(value: T[N]): void {
            if (parent === undefined) {
                return;
            }

            parent.update((obj: T) => {
                return {
                    ...(obj||{}),
                    [name]: value
                } as any;
            });
        },

        update(updater: Updater<T[N]>): void {
            if (parent === undefined) {
                return updater(undefined as any), void 0;
            }

            parent.update((obj: T) => {
                const newValue = updater(obj[name]);
                return {
                    ...(obj||{}),
                    [name]: newValue
                } as any;
            });
        }
    }
}

export function index<T>(parent: Writable<T[]>, index: number): Writable<T> {
    return {
        subscribe(func: Subscription<T>): Unsubscriber {
            return parent.subscribe((obj: T[]) => {
                if (!obj || obj.length < index) return func(undefined as any);
                func(obj[index]);
            });
        },

        set(value: T): void {
            parent.update((obj: T[]) => {
                obj = [...(obj||[])];
                obj[index] = value;
                return obj;
            });
        },

        update(updater: Updater<T>): void {
            parent.update((obj: T[]) => {
                obj = [...(obj||[])];
                obj[index] = updater(obj[index]);
                return obj;
            });
        }
    }
}

export function translate<S, D>(parent: Writable<S>, options: {from: (v: S) => D, to: (v: D) => S}): Writable<D> {
    return {
        subscribe(func: Subscription<D>): Unsubscriber {
            if (!parent) return () => {};
            return parent.subscribe((s: S) => func(options.from(s)));
        },
        set(value: D) {
            if (!parent) return;
            parent.set(options.to(value));
        },
        update(update: Updater<D>) {
            if (!parent) return update(undefined as any), void 0;
            parent.update(src => options.to(update(options.from(src))));
        }
    }
}

export function indexes(parent: Writable<any[]>): Readable<number[]> {
    return derived(parent, (values) => {
        return values.map((_, i) => i);
    });
}


export function unstable<T>(base: T, revert: number): Writable<T> {
    let idx: Timeout|null = null;
    const parent = writable(base);

    const _updateUnstable = () => {
        if (idx !== null) {
            clearTimeout(idx);
        }

        idx = setTimeout(() => {
            parent.set(base);
            idx = null;
        }, revert);
    }
    return {
        subscribe(func: Subscription<T>): Unsubscriber {
            return parent.subscribe(func);
        },
        set(value: T) {
            _updateUnstable();
            parent.set(value);
        },
        update(updater: Updater<T>) {
            parent.update(src => {
                _updateUnstable();
                return updater(src);
            });
        }
    }
}

export function onlyChanges<T>(parent: Writable<T>): Writable<T> {
    let myIdx = 0;
    let parentUnsubscribe = () => {};

    let lastKnownValue: T|undefined = undefined;

    let subscriptions: Map<number, (v: T) => void> = new Map();

    return {
        subscribe(subscriber: (v: T) => void) {
            const idx = myIdx++;
            subscriptions.set(idx, subscriber);
            if (subscriptions.size === 1) {
                parentUnsubscribe = parent.subscribe((newValue) => {
                    if (lastKnownValue === newValue) return;
                    lastKnownValue = newValue;

                    console.log("", newValue, lastKnownValue);

                    for (let subscription of subscriptions.values()) {
                        subscription(newValue);
                    }
                });
            }

            return () => {
                subscriptions.delete(idx);
                if (subscriptions.size === 0) {
                    parentUnsubscribe();
                    parentUnsubscribe = () => {};
                }
            }
        },

        set(v: T) {
            if (lastKnownValue === v) return;
            parent.set(v);
        },

        update(u: (v: T) => T) {
            parent.update((v) => {
                return u(v);
            });
        }
    }
}

export function debounced<T>(parent: Readable<T>, delay: number): Readable<T> {
    return readable(get(parent), set => {
        let idx: Timeout|null = null;
        let value = get(parent);
        set(value);
        const subscription = parent.subscribe(s => {
            if (idx !== null) clearTimeout(idx);
            idx = setTimeout(() => set(s), delay);
        });

        return () => {
            if (idx !== null) clearTimeout(idx);
            subscription();
        };
    })
}

export function debounce<T>(parent: Writable<T>, delay: number): Writable<T> {
    const self = writable<T>();

    let subscriptionCount = 0;
    let parentUnsubscribe = () => {};
    let timeoutId: Timeout|null = null;
    let timeoutFunction: () => void = () => {};

    function cancelTimeout() {
        if (timeoutId !== null) {
            clearTimeout(timeoutId)
            timeoutId = null;
            timeoutFunction = () => {};
        };
    }

    function moveTimeoutForward() {
        timeoutFunction();
        cancelTimeout();
    }

    function setTimed(value: T): void {
        cancelTimeout();
        timeoutFunction = () => {
            parent.set(value);
        };
        timeoutId = setTimeout(() => {
            timeoutFunction();
            timeoutId = null;
        }, delay);
    }

    return {
        subscribe(subscriber: (v: T) => void): () => void {
            subscriptionCount++;
            if (subscriptionCount === 1) {
                parentUnsubscribe = parent.subscribe((v) => {
                    self.update((vOld) => {
                        if (v === vOld) return vOld;
                        cancelTimeout();
                        return v;
                    });
                });
            }

            const unsubscriber = self.subscribe(subscriber);

            return () => {
                unsubscriber();

                subscriptionCount--;
                if (subscriptionCount === 0) {
                    parentUnsubscribe();
                    parentUnsubscribe = () => {};
                    moveTimeoutForward();
                }
            };
        },
        set(value: T) {
            self.set(value);
            setTimed(value);
        },
        update(updater: (v: T) => T) {
            self.update((v) => {
                const newValue = updater(v);
                setTimed(newValue);
                return newValue;
            });
        }
    }
}


export function depromise<T>(def: T, parent: Readable<Promise<T>>): Readable<T> {
    return readable(def, set => {
        let idx = 0;
        const subscription = parent.subscribe(s => {
            const myId = ++idx;
            s.then(v => idx === myId ? set(v) : {}).catch(window.reportError);
        });
        return () => {
            idx++;
            subscription();
        }
    });
}

export function promise2readable<T, D>(def: D, promise: Promise<T>): Readable<T|D> {
    return readable<T|D>(def, (set) => {
        let apply = (v: T) => set(v);
        promise.then(apply);
        return () => {
            apply = () => {};
        }
    });
}

export function asyncGeneratorToArray<T>(def: T[], parent: Readable<AsyncGenerator<T>>): Readable<T[]> {
    return readable(def, set => {
        let idx = 0;

        const subscription = parent.subscribe(s => {
            const current: T[] = [];
            const myIdx = ++idx;
            (async () => {
                while (true) {
                    const {done, value} = await s.next();
                    if (myIdx !== idx || done) break;
                    current.push(value);
                    set([...current]);
                }
            })().catch(window.reportError);
        });

        return () => {
            idx++;
            subscription();
        }
    })
}


export function filter<T>(store: Writable<T>, filter: (value: T) => T): Writable<T> {
    return {
        subscribe(subscriber: (v: T) => void): () => void {
            return store.subscribe(subscriber);
        },
        set(value: T) {
            return store.set(filter(value));
        },
        update(updater: (v: T) => T) {
            return store.update((v) => filter(updater(v)));
        }
    };
}


function onStorage(type: string, storage: Storage): (name: string) => Writable<string|null> {
    return (name: string) => {
        const channelName = `$store:${type}:${name}`;
        const reader = readable<string|null>(storage.getItem(name), (set) => {
            const brd = new BroadcastChannel(channelName);
            const update = () => set(storage.getItem(name));
            brd.addEventListener("message", update);
            return () => { brd.close(); }
        });

        function set(value: string|null) {
            if (value === null) {
                storage.removeItem(name);
            } else {
                storage.setItem(name, value);
            }

            const ch = new BroadcastChannel(channelName);
            ch.postMessage({});
            ch.close();
        }

        return {
            ...reader,
            set,
            update(u) {
                set(u(storage.getItem(name)));
            }
        }
    }
}

export const onSessionStorage = onStorage("session", window.sessionStorage);
export const onLocalStorage = onStorage("local", window.localStorage);


export function addWritable<V>(parent: Readable<V>, set: (value: V) => void = () => {}): Writable<V> {
    return {
        ...parent,
        set(v) { set(v) },
        update(u: Updater<V>) {
            let cb: (v: V) => void = (v) => { set(u(v)); cb = (v) => {}; unsubscriber = (u) => u(); }
            let unsubscriber: (unsubscribe: () => void) => void = (u) => {unsubscriber = u;};
            unsubscriber(parent.subscribe(cb));
        }
    }

}


export function poll<V>(time: number, cb: () => V): Readable<V> {
    let last = cb();
    return readable(last, (set) => {
        set(last);
        const timeout = setTimeout(() => set(last = cb()), time);
        return () => {
            clearTimeout(timeout);
        }
    });
}
