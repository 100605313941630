import { l } from "@/utils/translations";
import { Readable, Writable, writable } from "svelte/store";
import { promiseDelegate } from "@/utils/promises";


let locale: string = "de";


export function getGlobalLocale(): string {
    return locale;
}

export function setGlobalLocale(code: string) {
    locale = code;
}


export type Translator = (key: string, values?: any[], variables?: string[]) => string;
const __I18n: Writable<Translator> = writable(() => "");
export const _: Readable<Translator> = __I18n;

let _currentTranslator: Translator = () => "";
__I18n.subscribe(t => {_currentTranslator = t});
export const $_: Translator = (key, val, names) => _currentTranslator(key, val, names);


export class PriceValue {
    private amount: number;

    constructor(amount: number) {
        this.amount = amount;
    }

    toString() {
        return l(this.amount, {currency: true});
    }

    valueOf() {
        return this.amount;
    }
}

const [loadedP, loadedSignal, _unused1] = promiseDelegate<void>();
export function allTranslationsLoded(cb: () => void) {
    loadedP.then(() => cb());
}


export function setTranslator(t: Translator) {
    __I18n.set(t);
    loadedSignal();
}

