import { SvelteComponent } from "svelte";
import { entrypointModule } from "@/utils/pages";

import { components } from "@/frontend/base/state";
import { onCartLoaded } from "@frontend/cart";
import { CustomElement } from "@frontend/component_set";


interface Constructor<T> {
    new(...args: any[]): T;
}

import "./environment";
import "./spinner";
import { handleComponentError } from "@/utils/svelte/errors";

function defineSvelteComponent(cb: () => Promise<{default: Constructor<SvelteComponent>}>): () => Promise<{default: CustomElement}> {
    function readAttributes(element: HTMLElement): Map<string, string> {
        const current = new Map();
        for (let attribute of element.getAttributeNames()) {
            current.set(attribute, element.getAttribute(attribute)!);
        }
        return current;
    }

    function diffAttributes(before: Map<string, string>, after: Map<string, string>): Map<string, string|null> {
        const result = new Map();
        for (let name of after.keys()) {
            if (!before.has(name) || before.get(name) !== after.get(name)) {
                result.set(name, after.get(name));
            }
        }

        for (let name of before.keys()) {
            if (!after.has(name)) {
                result.set(name, null);
            }
        }

        return result;
    }

    function asObject(map: Map<string, string|null>): Record<string, string|null> {
        const result: Record<string, string|null> = {}
        for (let [key, value] of map.entries()) {
            result[key] = value;
        }
        return result;
    }

    return async () => {
        const impl = (await cb()).default;

        return {default: {
            apply(element: HTMLUnknownElement) {
                let attrs = readAttributes(element);
                const component = new impl({
                    target: element,
                    props: asObject(attrs)
                });
                handleComponentError(component);

                const observer = new MutationObserver((events) => {
                    for (let _ of events) {
                        const newAttrs = readAttributes(element);

                        const changes = diffAttributes(attrs, newAttrs);
                        if (changes.size == 0) continue;
                        attrs = newAttrs;

                        component.$set(asObject(changes));
                    }
                });
                observer.observe(element, {childList: false, attributes: true});

                return () => {
                    observer.disconnect();
                    component.$destroy();
                };
            }
        }};
    }
}

function ensureCartLoaded(cb: () => Promise<{default: CustomElement}>): () => Promise<{default: CustomElement}> {
    return async () => {
        const elem = (await cb()).default;
        await new Promise<void>(rs => onCartLoaded(rs));
        return {default: {
            apply(element: HTMLUnknownElement) {
                return elem.apply(element);
            }
        }}
    }
}

components.set("tmws-event-selector", ensureCartLoaded(defineSvelteComponent(() => import("./UnseatedEventSelector.svelte"))));
components.set("tmws-multi-event-selector", ensureCartLoaded(defineSvelteComponent(() => import("./UnseatedMultiEvent.svelte"))));
components.set("tmws-event-total", ensureCartLoaded(defineSvelteComponent(() => import("./UnseatedEventSum.svelte"))));
components.set("tmws-event-ticket-form", ensureCartLoaded(defineSvelteComponent(() => import("./UnseatedTicketForm.svelte"))));
components.set("tmws-event-sell", ensureCartLoaded(defineSvelteComponent(() => import("./UnseatedEventSell.svelte"))));
components.set("tmws-gift-card-selector", ensureCartLoaded(defineSvelteComponent(() => import("./GiftCardSelector.svelte"))));
components.set("tmws-header-cart", ensureCartLoaded(defineSvelteComponent(() => import("./HeaderCart.svelte"))));
components.set("tmws-header-timer", ensureCartLoaded(defineSvelteComponent(() => import("./HeaderTimer.svelte"))));
components.set("tmws-viewport", ensureCartLoaded(defineSvelteComponent(() => import("./SeatedViewport.svelte"))));
components.set("tmws-chart-buttons", ensureCartLoaded(defineSvelteComponent(() => import("./SeatedButtons.svelte"))));
components.set("tmws-infinite-scroll", ensureCartLoaded(defineSvelteComponent(() => import("./InfiniteScroll.svelte"))));
components.set("tmws-google-maps", ensureCartLoaded(defineSvelteComponent(() => import("./GoogleMaps.svelte"))));

entrypointModule("_v2/checkout", () => import("./checkout"));
entrypointModule("_v2/retry", () => import("./retry"));
entrypointModule("_v2/embed_payment", () => import("@/cart/checkout/payment"));
entrypointModule("_v2/calendar", () => import("./calendar"));
