export type UnseatedTicket = {event: number, category: number, discount?: number|null};


export interface UnseatedOptions {
    form: Record<string, string>,
    coupon: string;
}


export interface DiscountPriceData {
    id: number,
    ecdName: string,
    price: number
}


export interface ContingentPriceData {
    id: number,
    ecName: string,
    deleted: boolean,
    price: number,
    discounts: DiscountPriceData[],
}


export interface PriceData {
    id: number,
    evName: string,
    evDate: Date,
    categories: ContingentPriceData[];
}


export interface SnapshotItem {
    type: "permit"|"coupon"|"rebate"|"fee";
    id: number|null;
    price: number;
    vat_price: number;
}

export interface BundleDescription {
    id: string;
    name: string;
}


export interface PaidBooking {
    id: string,
    receipt_url: string,
    ticket_url: string
}


export interface Permit extends SnapshotItem {
    type: "permit",

    bundle_run: string|null,

    event_id: number,
    event_name: string,
    event_date: Date,

    category_id: number,
    category_name: string,

    base_discount_id: number|null,
    base_discount_name: string|null,

    // Invariant: base_discount_name in discounts.
    discounts: string[],

    seat: {seat_name: string, id: string, row_name: string, block_name: string}|null,
    event_tags: string[]
}

export interface Coupon extends SnapshotItem {
    type: "coupon"
}

export interface Rebate extends SnapshotItem {
    type: "rebate",
    name: string
}

export interface Fee extends SnapshotItem {
    type: "fee",
    bundle_run: string|null,
    fee_type: string
    description: string;
}

export interface Snapshot {
    items: SnapshotItem[],
    expiry_time: Date|null
}

/**
 * Stores information about a seat.
 */
export interface Seat {
    id: number,
    row_id: number,
    block_id: number,
    category_id: number

    x: number,
    y: number,
    status: "FREE"|"BLOCKED"|"RESERVED"|"SOLD"

    seat_name: string,
    row_name: string,
    block_name: string,
}


/**
 * Contains metadata about a seating chart.
 */
export interface SeatingChart {
    /**
     * Contains the ids of each block of the seating chart.
     */
    blocks: number[],

    /**
     * Returns the colors of each category.
     */
    colors: {[id: string]: string},

    /**
     * The bounding-box according to seat placements.
     */
    bbox: {width: number, height: number},

    /**
     * The URL that points to the background
     */
    background_url: string
}

/**
 * Represents an event.
 */
export interface Signal<T> {

    /**
     * Call this function to subscribe to an event.
     * The function will fire at least once,
     * namely when the initial value has been resolved
     * (which might happen immediately)
     *
     * The returned callable can be used to unsubscribe again.
     */
    subscribe(subscriber: (value: T) => void): () => void;

}


/**
 * Search-Options for events
 */
export interface EventOptions {
    page: number;
    page_size: number;

    search: string;
    name: string;
    from: Date;
    to: Date;
    tag: string;

    id: number;
}


export interface EventData {
    id: number,
    name: string;
    badge: string,
    entrytime: Date,
    starttime: Date,
    endtime: Date,
    description: string,
    tags: string[],
    seated: boolean
    state: {
        pricesShown: boolean,
        saleActive: boolean
    }
}


export interface PermitOptions {
    form?: Record<string, string>
    coupon: string
}


export interface BookingInformation {
    id: string,
    bookingNumber: string,
    pdfURL: string,

    waiting: boolean,

    tickets: {event_id: number, category_id: number, pdfURL: string, pkpassURL: string, gpayURL: string; }[],
    coupons: {value: number|null, code: string|null, rebate_name: string}[]
}


export interface CartTransaction {
    /**
     * Does nothing, except it forces a reload of the cart.
     */
    ensureReloadCart(): Promise<void>,

    /**
     * Adds an unseated ticket to the cart.
     */
    addUnseatedPermit(permit: UnseatedTicket, count: number, options: PermitOptions): Promise<void>,

    /**
     * Updates the form given on the sellable
     */
    updateSellable(sellable_id: number, type: UnseatedTicket|null, options: PermitOptions|null): Promise<void>;

    /**
     * Removes a sellable from the cart.
     */
    removeSellable(sellable_id: number): Promise<void>,

    /**
     * Blocks a seat
     */
    blockSeat(event_id: number, seat_id: number, discount_id: number|null): Promise<void>,

    /**
     * Unblocks a seat
     */
    unblockSeat(event_id: number, seat_id: number): Promise<void>,
}


export interface CheckoutEvents {
    success(id: string): Promise<void>,
    cancel?(): Promise<void>
}


/**
 * Connectors allow porting the SDK to different environments,
 * like embedded environments
 */
export interface Connector {

    /**
     * Initializes the connector.
     *
     * Receives a opaque string as a session id.
     * If there is no session-id, keep it empty.
     *
     * Returns a string with the session-id.
     * If the connector handles storing the session-id itself,
     * the string will be void.
     */
    initialize(data?: string): Promise<string|void>,

    ///////////////////////////////////////////////////////////////////
    // Cart

    /**
     * This signal is fired when the cart updates.
     */
    whenCartUpdated(): Signal<Snapshot>,

    /**
     * Runs the code in a transaction. The changes may be batched.
     * @param block The block.
     */
    runTransaction(block: (tx: CartTransaction) => Promise<void>): Promise<void>;

    /**
     * Tries to perform the cart changes within the transaction,
     * but does not persist them.
     *
     * This allows to check if a particular operation would work.
     *
     * @param block The block.
     */
    tryTransaction(block: (tx: CartTransaction) => Promise<void>): Promise<Snapshot>;

    /**
     * Shows the forms.
     */
    showForms(): Promise<Map<number, Record<string, string>>>;

    ///////////////////////////////////////////////////////////////////
    // Seating Chart

    /**
     * Gets the data about a seating chart.
     */
    getSeatingChart(event_id: number): Promise<SeatingChart>,

    /**
     * Returns the seats of a seating chart.
     */
    getSeats(event_id: number, block_id: number): Promise<Seat[]>,

    /**
     * Returns changes about a seat.
     */
    seatUpdated(event_id: number): Signal<Seat>,

    /**
     * Returns seat
     */
    blockedSeats(event_id: number): Signal<number[]>,
    
    ///////////////////////////////////////////////////////////////////
    // Unseated Tickets

    priceFor(ticket: UnseatedTicket): Promise<number>;

    /**
     * Returns contingent data.
     */
    freeTicketsOfEvent(event_id: number, category_id: number): Promise<number>,
    
    ///////////////////////////////////////////////////////////////////
    // Display

    listEvents(options: Partial<EventOptions>): Promise<EventData[]>;

    /**
     * Returns the price of an event.
     */
    getEvent(event: number): Promise<PriceData|null>,

    /**
     * Returns the path to an event image.
     */
    getEventImageUrl(event: number, width: number, height: number, gravity: string): Promise<string>,
    
    ///////////////////////////////////////////////////////////////////
    // Coupons and Rebates

    /**
     * Checks the validity of a coupon code.
     */
    checkCouponCode(code: string): Promise<{success: boolean, reason: string}>,

    /**
     * Applies a given coupon code.
     */
    applyCouponCode(code: string): Promise<{success: boolean, reason: string}>

    /**
     * Tries to buy a new gift-card
     */
    buyGiftCard(amount: number, count: number): Promise<void>;

    /**
     * Tries to buy a new rebate-card
     */
    buyRebateCard(key: string, count: number): Promise<void>;

    /**
     * Applies a rebate
     */
    applyBundle(key: string): Promise<{success: boolean, reason: string}>;
    
    /**
     * Checks a rebate
     */
    checkBundle(key: string): Promise<{success: boolean, reason: string}>;

    /**
     * Lists applicable discounts
     */
    listBundles(options: {id?: string}): Promise<BundleDescription[]>;

    /**
     * Loads the bundle checker for in-browser validation.
     */
    loadBundleChecker(key: string): Promise<{settings: any, wasm_url: any}|null>;
    
    ///////////////////////////////////////////////////////////////////
    // Coupons and Rebates

    /**
     * Mounts the checkout-ui at the given element.
     *
     * This function returns a callback that should be run when the checkout element is removed.
     * The onSuccess-function is called when the booking has been created successfully.
     *
     * Return null if this feature is not supported.
     */
    mountCheckout(target: HTMLElement, events: CheckoutEvents): (() => void)|null;
    
    ///////////////////////////////////////////////////////////////////
    // Completed Payments

    /**
     * Returns a booking by its ID.
     */
    getPaidBooking(id: string): Promise<PaidBooking>
}


let _defaultBuilder: () => Connector = () => { throw new Error("No default connector set."); };

export function buildConnector(): Connector {
    return _defaultBuilder();
}

/**
 * Set the connector builder.
 *
 * (This function is not directly accessible to embedded users)
 */
export function setConnectorBuilder(builder: () => Connector): void {
    _defaultBuilder = builder;
}
