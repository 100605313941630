const BroadcastChannel: typeof window["BroadcastChannel"] = (
    !!window["BroadcastChannel"]
    ? window.BroadcastChannel
    : <any>(await import("../polyfills/broadcastchannel")).default
)


if (process.env.NODE_ENV !== 'production') {
    (window as any)["BroadcastChannelPolyfill"] = BroadcastChannel;
}

export default BroadcastChannel;

