import { pushCache, _ as raw, l } from "@/utils/translations";
import client from "@/utils/http";
import hash from "tm:tmng_version_hash";
import frontend from "tm:frontend";
import { PriceValue, setGlobalLocale, setTranslator, Translator } from "@/frontend/lib/translations";
import { setProvider } from "@/frontend/base/state";

function getLocale(): string {
    return (window as any).I18n.config.tag
}

setGlobalLocale((window as any).I18n.config.language_code);

const SEEN_UNKNOWN = new Set();


const seen = new Set();
let keySet: string[] = [];
function sendKey(key: string) {
    if (seen.has(key)) return;
    seen.add(key);
    if (keySet.length === 0) {
        setTimeout(() => {
            const data = new FormData();
            data.append("authenticity_token", (<HTMLMetaElement>(document.querySelector('meta[name="csrf-token"]')!)).content);
            for (let key of keySet) {
                data.append("keys[]", key);
            }
            navigator.sendBeacon(`/ajax/locale/${getLocale()}/custom`, data);
            keySet = [];
        });
    }
    keySet.push(key);
}



function setCurrentTranslator(t: Translator) {
    setTranslator(
        ((key, values, variables) => {
            sendKey(key);
            return t(key, values, variables);
        }) as Translator
    );
}



(async() => {
    const IntlMessageFormat = (await import("intl-messageformat")).default;
    (window as any)["IntlMessageFormat"] = IntlMessageFormat;
    const STATIC_STORAGE_KEY = `Translations::static::${getLocale()}`
    const CUSTOM_CACHE_STORAGE_KEY = `Translations::custom::${getLocale()}`

    function buildNamedKeys(key: string, variables: string[]) {
        return `${key}::[${variables.join(",")}]`
    }

    function createTranslator(map: {[key: string]: string}): Translator {
        const intlCache: Record<string, any> = {};

        // Push the translations into the tr-cache.
        for (let [k, v] of Object.entries(map)) {
            pushCache(k, v);
        }

        return (key, values=[], variables=[]) => {
            const realKey = `frontend.${key}`;
            if (map[key] !== undefined)
                pushCache(realKey, map[key]);

            const namedKey = buildNamedKeys(key, variables);
            if (intlCache[namedKey] === undefined) {
                const defaultValue = `Missing: ${key}`
                let text = raw(realKey, defaultValue);
                if (defaultValue === text && !SEEN_UNKNOWN.has(key)) {
                    SEEN_UNKNOWN.add(key);
                    console.log(`Found missing translation: ${key}`);
                }
                /* Replace old translations with new ones. */
                for (let i = 0; i<variables.length; i++) {
                    text = text.replaceAll(`{${i}}`, `{ legacy_var_${i} }`);
                }


                let parsed;
                try {
                    parsed = new IntlMessageFormat(text, getLocale(), {}, {ignoreTag: true});
                } catch (e) {
                    window.reportError(e, {translationKey: key, translationVariables: variables, translationText: text});
                    parsed = {format: () => "Fehler in der Übersetzung..."}
                }
                intlCache[namedKey] = parsed;
            }

            const mappedValues: Record<string, any> = {};
            for (let i = 0; i<variables.length; i++) {
                const value = values[i];
                if (value instanceof PriceValue) {
                    mappedValues[variables[i]] = value.valueOf();
                    mappedValues[`legacy_var_${i}`] = l(value.valueOf(), {currency: true});
                } else if (value instanceof Date) {
                    mappedValues[variables[i]] = value;
                    mappedValues[`legacy_var_${i}`] = l(value, {type: "datetime"});
                } else if (value === undefined || value === null) {
                    mappedValues[variables[i]] = value;
                    mappedValues[`legacy_var_${i}`] = "";
                } else {
                    mappedValues[variables[i]] = value;
                    mappedValues[`legacy_var_${i}`] = value.toString();
                }
            }

            const parts = intlCache[namedKey].format(mappedValues);
            if (parts instanceof Array) {
                return parts.map((c: any) => c.toString()).join("");
            }
            return parts.toString();
        }
    };

    function getCachedStaticText(): any {
        if (sessionStorage.getItem(STATIC_STORAGE_KEY) === null) {
            return null;
        }

        const data = JSON.parse(sessionStorage.getItem(STATIC_STORAGE_KEY)!);
        if (data.hash != hash || data.template != frontend.template) {
            sessionStorage.removeItem(STATIC_STORAGE_KEY);
            return null;
        }

        return data.values;
    };

    function getDynamicStaticText(): any {
        if (!(window as any)["DynamicTranslations"]) return null;
        function flatten(cfg: object, rec: Record<string, string>, prefix: string) {
            for (let [k, v] of Object.entries(cfg)) {
                const fullKey = `${prefix}${prefix ? "." : ""}${k}`;
                if (v === undefined || v === null) {
                    v = "";
                }
                if (typeof v === "string")
                    rec[fullKey] = v;
                else
                    flatten(v, rec, fullKey);
            }
            return rec;
        }
        const translations = flatten((window as any).DynamicTranslations[getLocale()].javascript.frontend, {}, "");
        return translations;
    }

    let staticText = getDynamicStaticText() || getCachedStaticText();
    if (staticText === null) {
        const values = (await client.get(`/ajax/locale/${getLocale()}/static`)).data;
        sessionStorage.setItem(STATIC_STORAGE_KEY, JSON.stringify({hash, values, template: frontend.template}));
        staticText = values;
    }

    if (sessionStorage.getItem(CUSTOM_CACHE_STORAGE_KEY) !== null) {
        setCurrentTranslator(createTranslator({...staticText, ...JSON.parse(sessionStorage.getItem(CUSTOM_CACHE_STORAGE_KEY)!)}));
    } else {
        setCurrentTranslator(createTranslator(staticText));
    }

    const custom_requests = (await client.get(`/ajax/locale/${getLocale()}/custom`)).data;
    sessionStorage.setItem(CUSTOM_CACHE_STORAGE_KEY, JSON.stringify(custom_requests));
    setCurrentTranslator(createTranslator({...staticText, ...custom_requests}) as Translator);

})().catch(window.reportError);

/////////////
// Settings


const currentKnown: Record<string, any> = {};
let settingList: string[] = [];
let waiter: Promise<void>|null = null;

setProvider(async (name) => {
    if (currentKnown[name] !== undefined)
        return currentKnown[name];

    if (waiter === null) {
        waiter = (async () => {
            await Promise.resolve(null);
            waiter = null;
            const currentRequests = Array.from(new Set(settingList));
            settingList = [];
            const result = await client.get("/ajax/settings", {params: {settings: currentRequests}})
            for (let name of currentRequests) {
                currentKnown[name] = result.data.result[name];
            }
        })();
    }
    settingList.push(name);
    await waiter;

    return currentKnown[name];
})
