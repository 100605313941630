import { Booking } from "./booking";
import { Cart } from "./cart";
import { registerAll, registerSplit } from "./components";
import { CartTransaction, Connector, EventOptions, Signal, UnseatedTicket, Snapshot, buildConnector } from "./connector";
import { Category, Discount, Event, EventPriceInformation } from "./event";
import { DefaultTranslator, Translator } from "./translations";


export interface ShopOptions {
    /**
     * The TicketMachine JavaScript SDK defines some web-components for
     * easy, script-less embeddings.
     *
     * This defines the namespace to use.
     */
    componentPrefix: string;

    /**
     * A store for the session
     */
    sessionStore: {
        /**
         * Store the current session token.
         */
        save: (data: string|null) => Promise<void>,

        /**
         * Load the current session token.
         */
        load: () => Promise<string|null>
    },

    /**
     * Called when an error happens.
     * By default it prints the error to the console.
     */
    onError: (error: Error) => void;

    /**
     * By default, the SDK will not automatically define the web-components.
     * Instead, it will wait for the component to be used, and only then
     * load the required javascript.
     *
     * Set this to true to predefine the components automatically.
     */
    preloadComponents: boolean;

    /**
     * The translator is something you define to override the default translations
     * of the SDK.
     */
    translator: Signal<Translator<any>>;

    /**
     * When set to true, this will be the global shop-connector.
     * This option can be used to use multiple shops on the same page.
     * 
     * Components can only be used for public shops.
     */
    public: boolean;

    /**
     * Internal. You won't be able to directly change this.
     */
    connector: Connector;

}


function mergeDefaults(options: Partial<ShopOptions>): ShopOptions {
    return {
        connector: (options.connector || buildConnector()),
        preloadComponents: false,
        componentPrefix: "tm",
        onError: (e) => console.error(e),
        translator: DefaultTranslator,
        sessionStore: {
            async save(value: string|null) {
                if (value === null) {
                    localStorage.removeItem("tm:embed");
                } else {
                    localStorage.setItem("tm:embed", value);
                }
            },

            async load() {
                return localStorage.getItem("tm:embed")
            }
        },
        public: true,
        ...options
    }
}

type ExtendedTransaction = CartTransaction&{
    runTransaction(block: (tx: CartTransaction) => Promise<void>): Promise<void>,
    onTransactionCompleted(cb: () => void): Promise<void>
};

function buildBaseForwarder(runTrx: (trx: ExtendedTransaction) => Connector["runTransaction"], forwarder: (name: keyof ExtendedTransaction) => (...args: any[]) => any): ExtendedTransaction {
    const trx: ExtendedTransaction = {
        ensureReloadCart:       forwarder("ensureReloadCart"),
        addUnseatedPermit:      forwarder("addUnseatedPermit"),
        updateSellable:         forwarder("updateSellable"),
        removeSellable:         forwarder("removeSellable"),
        blockSeat:              forwarder("blockSeat"),
        unblockSeat:            forwarder("unblockSeat"),
        onTransactionCompleted: forwarder("onTransactionCompleted"),
        
        runTransaction: (block: (tx: CartTransaction) => Promise<void>) => runTrx(trx)(block),
    }
    return trx;
}

async function makeBatcher(parent: ExtendedTransaction, block: (trx: ExtendedTransaction) => Promise<void>): Promise<void> {
    const ops: [keyof CartTransaction, any[]][] = [];

    const forwarder = (name: keyof CartTransaction) => async (...args: any[]) => {ops.push([name, args])};
    const runTransaction = (trx: ExtendedTransaction) =>  async (b: (tx: CartTransaction) => Promise<void>) => {
        await makeBatcher(trx, b);
    }

    await block(buildBaseForwarder(runTransaction, forwarder));

    for (let [name, args] of ops) {
        await (parent[name] as any)(...args);
    }
};


export type ShopConnector = Connector&ExtendedTransaction;


function staticTranslator(signal: Signal<Translator<any>>): Translator<any> {
    let currentTranslator: Translator<any> = () => "";
    signal.subscribe(t => {currentTranslator = t});
    return (...args: any[]) => (currentTranslator as Function)(...args);
}


export default class Shop {
    private options: ShopOptions;
    private static __current: Shop|null = null;
    private _session_id: string|null;
    private _currentTrx: ExtendedTransaction|null = null;
    private _currentSTL: Translator<any>;

    private constructor(options: ShopOptions) {
        this.options = options;
        this._currentSTL = staticTranslator(this.options.translator);
    }

    static async create(options: Partial<ShopOptions> = {}): Promise<Shop> {
        const fullOptions = mergeDefaults(options);

        if (Shop.__current !== null && Shop.current !== undefined && fullOptions.public) {
            throw Error("A TicketMachine-Shop is already configured for this web-page.");
        }

        const shop = new Shop(fullOptions);
        if (fullOptions.public) Shop.__current = shop;
        await shop.setup();
        return shop;
    }

    get translator(): Signal<Translator<any>> {
        return this.options.translator;
    }

    static get current(): Shop {
        if (Shop.__current === null) {
            throw Error("A TicketMachine-Shop is not configured for this web-page.");
        }
        return Shop.__current;
    }

    get cart(): Cart {
        return new Cart(this.connector, this._currentSTL);
    }

    get connector(): ShopConnector {
        const ctrx = (b: (ctx: ExtendedTransaction) => Promise<void>) => this.__runTransaction("runTransaction", b);

        const forwarder = (name: keyof CartTransaction) => async (...args: any[]) => { 
            await ctrx(async (trx: CartTransaction) => {
                (trx[name] as any)(...args);
            });
        };
        const runTransaction = (_: ExtendedTransaction) => async (block: (tx: CartTransaction) => Promise<void>) => {
            await ctrx(async (trx: CartTransaction) => block(trx));
        };


        return {
            ...this.options.connector,
            ...buildBaseForwarder(runTransaction, forwarder),
        }
    }

    private async __runTransaction(type: "runTransaction"|"tryTransaction", block: (c: ExtendedTransaction) => Promise<void>): Promise<any> {
        const store = async (trx: ExtendedTransaction) => {
            this._currentTrx = trx;
            await block(trx);
            this._currentTrx = null;
        } ;
        if (this._currentTrx === null) {
            const finishSignals: (()=>void)[] = [];
            const result = 
                await this.options.connector[type](async (t) => {
                    await makeBatcher({
                        ...t,
                        runTransaction(){return Promise.resolve()},
                        onTransactionCompleted: (
                            (t as any)["onTransactionCompleted"] 
                            ? (cb: () => void) => (t as any).onTransactionCompleted(cb)
                            : (cb: () => void) => {finishSignals.push(cb); return Promise.resolve()}
                        )
                    }, store);
                });
            finishSignals.forEach(s => s());
            return result;
        } else {
            await makeBatcher(this._currentTrx, store);
        }
    }

    async runTransaction(block: (s: Shop) => Promise<void>): Promise<void> {
        const opts = this.options;
        await this.__runTransaction("runTransaction", async (ctx: ExtendedTransaction) => {
            await block(new Shop({...opts, connector: {
                ...opts.connector,

                async initialize() { }, 
                async runTransaction(b) {
                    await makeBatcher(ctx, b);
                },
            }}));
        });
    }
    async tryTransaction(block: (s: Shop) => Promise<void>): Promise<Snapshot> {
        const opts = this.options;
        return await this.__runTransaction("tryTransaction", async (ctx: ExtendedTransaction) => {
            await block(new Shop({...opts, connector: {
                ...opts.connector,

                async initialize() { }, 
                async runTransaction(b) {
                    await makeBatcher(ctx, b);
                },
            }}));
        });
    }

    async* listEvents(options: Partial<EventOptions>): AsyncGenerator<Event> {
        const hasPage = options.page !== undefined;
        let page = options.page || 1;

        while (true) {
            const results = await this.connector.listEvents({...options, page});
            if (results.length === 0) break;
            page++;
            for (const result of results)
                yield new Event(result, this.connector);
            if (hasPage) return;
        }
    }

    async getEvent(id: number): Promise<Event> {
        const data = await this.connector.listEvents({id});
        if (data.length === 0)
            throw new Error("Event not found.");
        return new Event(data[0], this.connector);
    }

    async getEventPrices(id: number): Promise<EventPriceInformation> {
        const data = await this.connector.getEvent(id);
        if (data === null) {
            throw new Error("Event not found.");
        }
        return new EventPriceInformation(data, this.connector);
    }

    async resolve(ticket: UnseatedTicket): Promise<Category|Discount> {
        const event = await Shop.current.getEventPrices(ticket.event);
        const category = event.categories.find(c => c.id == ticket.category);
        if (!ticket.discount) return category;
        const discount = category?.discounts.find(d => d.id == ticket.discount);
        return discount;
    }

    get session_id(): string|null {
        return this._session_id;
    }

    private async setup() {
        const current = await this.options.sessionStore.load();
        const newSession = await this.options.connector.initialize(current === null ? undefined : current);
        await this.options.sessionStore.save(newSession || null);
        this._session_id = newSession || null;

        if (this.options.public) {
            await Promise.all([
                this.options.preloadComponents 
                    ? registerAll(this.options.componentPrefix) 
                    : registerSplit(this.options.componentPrefix),
            ]);
        }
    }

    async getBooking(booking_id: string) {
        const pb = await this.connector.getPaidBooking(booking_id);
        return new Booking(pb);
    }

    __error(error: Error): never {
        this.options.onError(error);
        throw error;
    }

    get onError() {
        return (e: Error) => this.__error(e);
    }
}
