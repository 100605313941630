import { Permit, SnapshotItem } from "../connector";

interface ListFragment {
    children: Node[];
}

function applyMultiple(...appliers: ((e: HTMLElement) => HTMLElement)[]) {
    return (e: HTMLElement) => {
        return appliers.reduce((p, n) => n(p), e);
    }
}

function applyAttribute(name: string, value: string) {
    return (e: HTMLElement) => {
        if (e.hasAttribute(name)) return e;
        e.setAttribute(name, value);
        return e;
    }
}

function id(e: HTMLElement) { return e };

function runApplicatorOnChildren(applicators: {[key: string]: ((e: HTMLElement) => HTMLElement)}, fragment: DocumentFragment): void {
    for (let [tag, applier] of Object.entries(applicators)) {
        for (let child of Array.from(fragment.querySelectorAll(tag))) {
            if (!(child instanceof HTMLElement)) continue;
            applier(child);
        }
    }
}

export function applyEventData(event: number, category: number|null, discount: number|null, fragment: DocumentFragment): void {
    const applyEvent = applyAttribute("event", ""+event);
    const applyCategory = (category !== null && category !== undefined) ? applyAttribute("category", ""+category) : id;
    const applyDiscount = (discount !== null && discount !== undefined) ? applyAttribute("discount", ""+discount): id;

    const APPLICATORS = {
        [`${TemplateAction._currentPrefix}-viewport`]: applyEvent,
        [`${TemplateAction._currentPrefix}-seatselect`]: applyEvent,

        [`${TemplateAction._currentPrefix}-contingent`]: applyMultiple(applyEvent, applyCategory),
        [`${TemplateAction._currentPrefix}-cart-total`]: applyMultiple(applyEvent, applyCategory, applyDiscount),

        [`${TemplateAction._currentPrefix}-article-price`]: applyMultiple(applyEvent, applyCategory, applyDiscount),
        [`${TemplateAction._currentPrefix}-article-count`]: applyMultiple(applyEvent, applyCategory, applyDiscount),
        [`${TemplateAction._currentPrefix}-article-action`]: applyMultiple(applyEvent, applyCategory, applyDiscount),

        [`${TemplateAction._currentPrefix}-event-image`]: applyEvent,
        [`${TemplateAction._currentPrefix}-event-link`]: applyEvent,
        [`${TemplateAction._currentPrefix}-event-data`]: applyMultiple(applyEvent, applyCategory, applyDiscount),
        [`${TemplateAction._currentPrefix}-discount-list`]: applyMultiple(applyEvent, applyCategory),
        [`${TemplateAction._currentPrefix}-category-list`]: applyEvent
    };


    runApplicatorOnChildren(APPLICATORS, fragment);
}

export function applySellableData(sellable: SnapshotItem, fragment: DocumentFragment) {
    runApplicatorOnChildren({
        [`${TemplateAction._currentPrefix}-cart-total`]: applyAttribute("ids", ""+sellable.id),
        [`${TemplateAction._currentPrefix}-article-price`]: applyAttribute("ids", ""+sellable.id),
        [`${TemplateAction._currentPrefix}-article-data`]: applyAttribute("article", ""+sellable.id),
        [`${TemplateAction._currentPrefix}-article-remove`]: applyAttribute("article", ""+sellable.id),

    }, fragment);

    if (sellable.type === "permit") {
        const permit = sellable as Permit;
        const eventId = permit.event_id;
        const categoryId = permit.category_id;
        const discountId = permit.base_discount_id;
        applyEventData(eventId, categoryId, discountId, fragment);
    }
}

export abstract class TemplateAction<C> extends HTMLElement {
    private _disconnect: () => void|null = null;
    private _contexts: Map<C, ListFragment> = new Map();

    static _currentPrefix: string|null = null;

    private get templates(): HTMLTemplateElement[] {
        return Array.from(this.querySelectorAll("& > template"));
    }

    private _abortRunner() {
        if (this._disconnect !== null) {
            this._disconnect();
            this._disconnect = null;
        }
    }

    invalidateContext(context: C) {
        this._contexts.delete(context);
    }

    private renderContexts(contexts: C[]): void {
        const newContext = new Map();
        for (let context of contexts) {
            let fragment = this._contexts.get(context);
            if (!fragment) {
                const rendered = this.render(context);
                fragment = {
                    children: Array.from(rendered.childNodes)
                }
            }
            newContext.set(context, fragment);
        }

        for (let child of Array.from(this.childNodes)) {
            if (child instanceof HTMLTemplateElement) {
                continue;
            }
            this.removeChild(child);
        }

        this._contexts = newContext;
        for (let context of contexts) {
            for (let newChild of newContext.get(context)!.children) {
                this.appendChild(newChild);
            }
        }
    }

    protected render(context: C): DocumentFragment {
        throw new Error("Not implemented");
    }

    protected run(render: (contexts: C[]) => void): (() => void) {
        throw new Error("Not implemented");
    }

    connectedCallback() {
        this._abortRunner();
        this._disconnect = this.run((cs) => this.renderContexts(cs));
    }

    disconnectedCallback() {
        this._abortRunner();
    }

    getTemplate(type: string = "default"): DocumentFragment|null {
        return (this.templates.find(t => t.getAttribute("type") == type || (type == "default" && !t.hasAttribute("type"))) as HTMLTemplateElement|null)?.content;
    }

}

