import { TemplateAction } from "./components/_TemplateAction";

const COMPONENTS: {[suffix: string]: () => Promise<typeof import("*.svelte") | {default: typeof HTMLElement}>} = {
    "viewport":                     () => import("./components/Viewport.svelte"),
    "seatselect":                   () => import("./components/SeatSelector.svelte"),
    "countdown":                    () => import("./components/Countdown.svelte"),
    "article-action":               () => import("./components/ArticleAction.svelte"),
    "article-count":                () => import("./components/ArticleCount.svelte"),
    "article-price":                () => import("./components/ArticlePrice.svelte"),
    "article-contingent":           () => import("./components/Contingent.svelte"),
    "cart-total":                   () => import("./components/CartTotal.svelte"),
    "event-image":                  () => import("./components/EventImage.svelte"),
    "gift-card-action":             () => import("./components/GiftCardAction.svelte"),
    "rebate-card-action":           () => import("./components/RebateCardAction.svelte"),
    "ticket-form":                  () => import("./components/TicketForm.svelte"),
    "checkout":                     () => import("./components/Checkout.svelte"),
    "booking-link":                 () => import("./components/BookingLink.svelte"),
    "event-data":                   () => import("./components/EventData.svelte"),
    "event-link":                   () => import("./components/EventLink.svelte"),
    "event-page":                   () => import("./components/EventPage"),
    "category-list":                () => import("./components/CategoryList"),
    "discount-list":                () => import("./components/DiscountList"),
    "article-remove":               () => import("./components/ArticleRemove.svelte"),
    "article-data":                 () => import("./components/ArticleData.svelte"),
    "cart":                         () => import("./components/Cart"),
    "event-list":                   () => import("./components/EventList"),
    "event-list-pagination-button": () => import("./components/EventListPaginationButton.svelte")
}


class ComponentSet {
    private observer: MutationObserver;
    private map: Map<string, () => Promise<typeof import("*.svelte")>> = new Map();
    private loaded: Set<string> = new Set();

    constructor() {
        this.observer = new MutationObserver((list) => {
            for (let { addedNodes, type } of list) {
                if (type !== "childList") continue;
                for (let node of Array.from(addedNodes).filter(n => n instanceof HTMLElement)) {
                    this._autodiscover(node as HTMLElement);
                }
            }
        });
    }

    set(name: string, element: () => Promise<typeof import("*.svelte")>) {
        if (this.loaded.has(name)) { return; }
        this.map.set(name, element);
    }

    async load(name: string) {
        if (this.loaded.has(name)) return;
        this.loaded.add(name);

        const loader = this.map.get(name)!;
        const module = await loader();

        let element = module.default;
        if (!!(element as any).element) element = (element as any).element;

        customElements.define(name, element as any);
    }

    _autodiscover(scope: HTMLElement) {
        (window["requestIdleCallback"] || window["requestAnimationFrame"])(() => {
            let candidates: Element[] = [scope, ...Array.from(scope.querySelectorAll("*"))];
            for (let el of candidates) {
                let tag = el.localName;
                if (tag.includes("-") && this.map.has(tag)) {
                    this.load(tag).catch(console.error);
                }
            }
        });
    }

    autodiscover(scope: HTMLElement) {
        this._autodiscover(scope);
        this.observer.observe(scope, {childList: true, subtree: true});
    }

    close() {
        this.observer.disconnect();
    }
}


function onLoad(c: () => void): void {
    (document.readyState !== "loading") ? c() : document.addEventListener("DOMContentLoaded", c);
}


/**
 * Registers all Web-Components by automatically loading the required module when the component is defined.
 */
export async function registerSplit(prefix: string): Promise<void> {
    TemplateAction._currentPrefix = prefix;

    const components = new ComponentSet();
    for (let suffix of Object.keys(COMPONENTS)) {
        components.set(`${prefix}-${suffix}`, COMPONENTS[suffix]);
    }

    onLoad(() => { components.autodiscover(document.body); });
}

/**
 * Registers all Web-Components at once. 
 */
export async function registerAll(prefix: string): Promise<void> {
    TemplateAction._currentPrefix = prefix;

    const loaded = Object.keys(COMPONENTS).map(k => ({suffix: k, promise: COMPONENTS[k]()}));

    await Promise.all(loaded.map(({promise}) => promise));

    for (let {suffix, promise} of loaded) {
        customElements.define(`${prefix}-${suffix}`, (await promise).default as any);
    }
}
